$font-body: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
$font-mono: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
  monospace;

$bp-desktop: 1156px;

%section {
  max-width: $bp-desktop;
  margin: 0 auto;
  padding: 0 28px;
}
