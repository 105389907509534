.mainHeader {
  @extend %section;
  padding-top: 40px;
  margin-bottom: 60px;
  text-align: center;

  &__title {
    font-size: 24px;
    margin: 0 0 32px;
  }

  &__titleLink {
    color: inherit;
    text-decoration: none;
  }

  &__badges {
    a {
      text-decoration: none;
    }
  }
}
